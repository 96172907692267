import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Globals } from '../../globals';
import { CommonModule } from '@angular/common';
import { CommonService } from '../../services/common.service';
import { Router, NavigationEnd } from '@angular/router';
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from "@progress/kendo-angular-label";
import { FormsModule } from '@angular/forms';
import { FormErrorComponent } from '../form-error/form-error.component';
import Swal from 'sweetalert2';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterModule, CommonModule, DialogsModule, InputsModule, LabelModule, FormsModule, FormErrorComponent],
  templateUrl: './header.component.html'
})
export class HeaderComponent {

  constructor(protected globals: Globals, private commonService: CommonService, private router: Router) { }


  navLinks = [
    { route: 'my-alerts', label: 'Notification', isExternalRoute: false },
  ];

  ngOnInit() {

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      if (event.urlAfterRedirects.includes('/admin/') || event.urlAfterRedirects.includes('/client/')) {
        this.globals.isAdminRoutePage = true;
      } else {
        this.globals.isAdminRoutePage = false;
      }
      this.commonService.buildNavItems();
    });

  }

  public impersonateDialogOpened = false;
  impersonateDialog() {
    this.impersonateUserDetails = null;
    this.impersonateUserError = "";
    this.impersonateUserEmail = "";
    this.impersonateDialogOpened = true;
  }
  impersonateCloseDialog() {
    this.impersonateDialogOpened = false;
  }

  impersonateUserEmail: string = "";
  isValidEmail(): boolean {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.impersonateUserEmail);
  }

  impersonateUserDetails: any = null;
  impersonateUserError: string = "";
  getUserDetailsForImpersonat() {
    this.impersonateUserError = "";
    this.globals.showLoader();
    this.commonService.getUserForImpersonate({ email: this.impersonateUserEmail }).then(
      (data: any) => {
        this.globals.hideLoader();
        if (data.data) {
          this.impersonateUserDetails = data.data;
        } else {
          if (data.code == 403) {
            this.impersonateUserError = "As this user is not a client user, you can not impersonate this user.";
          } else if (data.code == 424) {
            this.impersonateUserError = "User is not active!";
          } else {
            this.impersonateUserError = "User not found!";
          }
        }
      },
      (error) => {
      }
    );
  }

  clearImpersonateDetail() {
    this.impersonateUserDetails = null;
    this.impersonateUserEmail = "";
    this.impersonateUserError = "";
  }

  startImpersonate() {
    this.impersonateDialogOpened = false;
    this.globals.impersonateUserInfo = this.impersonateUserDetails;
    this.globals.impersonateUserRolesSlugs = this.globals.impersonateUserInfo.roles_slug.split("~");
    this.globals.clientId = this.globals.impersonateUserInfo.client_id;
    this.commonService.setCookie(this.globals.cookieImpersonateUserInfo, this.impersonateUserDetails, true);
    this.commonService.buildNavItems();
    this.router.navigate(['/my-alerts']);
    // this.commonService.showNotification('success', 'Impersonation started!');
  }

  stopImpersonate() {

    Swal.fire({
      title: 'Stop Impersonate?',
      text: 'Are you sure you want to stop impersonating "' + this.globals.impersonateUserInfo.name + '"?',
      icon: 'warning',
      confirmButtonText: 'Confirm',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        this.commonService.clearDataOfImpersonate();
        this.globals.clientId = this.globals.authData.client_id;
        this.commonService.buildNavItems();
        this.commonService.showNotification('success', 'Impersonation stopped!');
        Swal.fire({
          title: 'Impersonate again?',
          text: 'Do you want to impersonate another user?',
          confirmButtonText: 'Yes',
          showCancelButton: true,
          cancelButtonText: 'No',
        }).then((result) => {
          if (result.isConfirmed) {
            this.impersonateDialog();
          }
        });
      }
    });


  }

  hasAddress() {
    return (this.impersonateUserDetails.address_line_1 != null
      || this.impersonateUserDetails.address_line_2 != null
      || this.impersonateUserDetails.city != null
      || this.impersonateUserDetails.state != null
      || this.impersonateUserDetails.zip != null);
  }

  isCurrentRoute(link: NavItem, index: number): boolean {
    if (link.isExternalRoute == false || (link.isExternalRoute == true && link.route.split('/')[3] == 'client' && this.globals.currentLink.split('/')[index] == 'client')) {
      if (link.route.split('/')[index] == 'admin') {
        return link.route.split('/')[index] == this.globals.currentLink.split('/')[index]
          && link.route.split('/')[index + 1] == this.globals.currentLink.split('/')[index + 1];
      } else if (link.isExternalRoute == true && link.route.split('/')[3] == 'client') {
        return true;
      } else {
        if (this.globals.currentLink.split('/')[index] == 'admin' || this.globals.currentLink.split('/')[index] == 'client') {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  }

  get showImpersonateHeader() {
    return this.globals.authData?.role_id == 1 && !this.globals.impersonateUserInfo && this.globals.currentLink.split('/')[1] != 'admin' && this.globals.currentLink.split('/')[1] != 'client';
  }

  logout() {
    this.globals.showLoader();
    this.commonService.logout().then(
      (data: any) => {
        this.globals.hideLoader();
        this.commonService.showNotification(
          'success',
          'Successfully logged out!'
        );
        if (typeof window !== 'undefined') {
          window.location.href = this.globals.baseLogoutUrl;
        }
      },
      (error) => {
        this.globals.hideLoader();
        if (typeof window !== 'undefined') {
          window.location.href = this.globals.baseLogoutUrl;
        }
      }
    );
  }

  emailAlertSettingDialogOpened: boolean = false;
  emailAlertSetting: EmailAlertSetting = new EmailAlertSetting();
  emailAlertSettingCloseDialog() {
    this.emailAlertSettingDialogOpened = false;
  }



  emailAlertSettingDialog() {

    this.emailAlertSetting = new EmailAlertSetting();
    this.globals.showLoader();
    this.commonService.getEmailAlertFlagsByUser(this.globals.impersonateUserInfo.id).then(
      (data: any) => {
        this.globals.hideLoader();
        const d = data.data;
        this.emailAlertSetting.dnd_email_alert_on = d.is_email_alert_on == 1;
        this.emailAlertSetting.dnd_committee_email_alert_on = d.is_committee_email_alert_on == 1;
        this.emailAlertSettingDialogOpened = true;
      },
      (error) => {
        this.globals.hideLoader();
      }
    );
  }

  saveEmailAlert() {
    const entity = {
      is_email_alert_on: this.emailAlertSetting.dnd_email_alert_on ? 1 : 0,
      is_committee_email_alert_on: this.emailAlertSetting.dnd_committee_email_alert_on ? 1 : 0
    };
    this.globals.showLoader();
    this.commonService.saveEmailAlertFlagsByUser(this.globals.impersonateUserInfo.id, entity).then(
      (data: any) => {
        this.globals.hideLoader();
        this.emailAlertSettingDialogOpened = false;
        this.commonService.showNotification('success', 'Email alert setting of "' + this.globals.impersonateUserInfo.name + '" saved.');
      },
      (error) => {
        this.globals.hideLoader();
      }
    );
  }


}

export interface NavItem {
  route: string;
  label: string;
  isExternalRoute: boolean;
}

export class EmailAlertSetting {
  dnd_email_alert_on: boolean = false;
  dnd_committee_email_alert_on: boolean = false;
}
