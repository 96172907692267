import { Routes } from '@angular/router';
import { SetCurrentLinkGuard } from './shared/guards/set-current-link.guard';
import { AuthGuard } from './shared/guards/auth.guard';
import { RolePermissionGuard } from './shared/guards/role-permission.guard';

const Guard = [SetCurrentLinkGuard, AuthGuard, RolePermissionGuard];

export const routes: Routes = [
    { path: 'release-notes', loadComponent: () => import('./release-notes/release-notes.component').then(m => m.ReleaseNotesComponent) },
    { path: 'auth/login', loadComponent: () => import('./auth/auth.component').then(m => m.AuthComponent), canActivate: Guard, },
    { path: 'admin', loadChildren: () => import('./admin/admin.routes').then(m => m.adminRoutes), canActivate: Guard, },
    { path: 'client/advanced-configuration/:client_id/:category_id', loadComponent: () => import('./admin/client-tags-final-enactment/client-tags-final-enactment.component').then(m => m.ClientTagsFinalEnactmentComponent), canActivate: Guard, },
    { path: 'review', loadChildren: () => import('./bill-review/bill-review.routes').then(m => m.billReviewRoutes), canActivate: Guard, },
    { path: '', loadChildren: () => import('./client/client.routes').then(m => m.clientRoutes), canActivate: Guard, },
    { path: '**', redirectTo: '' }
];
